import * as React from "react"

const Breadcrumbs = (props) => {
    return (
        <div className="breadcrumbs">
            <span className="breadcrumbs-link">
                <a href="/">Home</a>
            </span>
            {props.steps && props.steps.map((step, index) => {
                return (
                    <span key={index} className="breadcrumbs-link">
                        <a href={step.path}>{step.name}</a>
                    </span>
                )
            })}
        </div>
    )
}

export default Breadcrumbs
