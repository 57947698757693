import  React, { useState } from "react"

//COMPONENTS
import Layout from "../../components/layout"
import Seo from "@components/Seo.js"
import Breadcrumbs from "../../components/Breadcrumbs"

//IMAGES
import User from "@images/free-trial/user.svg"
import Balance from "@images/free-trial/balance.svg"
import CheckOrder from "@images/free-trial/check-order.svg"
import LoadingOrder from "@images/free-trial/loading-order.svg"
import FinishOrder from "@images/free-trial/finish-order.svg"
import Tell from "@images/free-trial/tell.svg"
import TrialArrow from "@images/free-trial/trial-arrow.svg"

const FreeTrialPage = () => {

    const pageData = {
        title: "Free Trial",
        description: `Our special pledge to new clients is that they can try our service For Free!`,
        keywords: `essay for free, free term paper, free essay, free essays`
    }

    const steps = [
        {
            name: 'Free trial',
            path: '/free-trial'
        }
    ]

    const [btnIsDisebled, setBtnIsDisebled] = useState(false)

    const cards = [
        {
            icon: User,
            alt: 'register an account',
            title: {
                __html: '<span>Register</span> in seconds '
            },
            description: 'Register in our application with your e-mail and password or your social network in just a few clicks.'
        },
        {
            icon: Balance,
            alt: 'get 25$',
            title: {
                __html: 'Get <span>$25.00</span>'
            },
            description: 'You will automatically be credited with $25.00 USD to spend on your first order.'
        },
        {
            icon: CheckOrder,
            alt: 'you first order',
            title: {
                __html: 'Order <span>for free</span>'
            },
            description: 'You can order a one or two page assignment to test our service free of charge.'
        },
        {
            icon: LoadingOrder,
            alt: 'log in',
            title: {
                __html: 'Get your project done by your <span>personal writer</span>'
            },
            description: 'One of our writers will be assigned to complete your project by your deadline. You can talk to the writer and send additional resources.'
        },

        {
            icon: FinishOrder,
            alt: 'get your paper',
            title: {
                __html: 'Receive the <span>finished paper</span>'
            },
            description: `That's it, your order is ready. You will get your file in the e-mail and can download it on our site as well.`
        },
        {
            icon: Tell,
            alt: 'tell you friends',
            title: {
                __html: 'Tell your friends about our <span>amazing service</span> and come back for more!'
            },
            description: `We will hope to see you again!`
        }
    ]

    const redirect = async () => {
        try {
            setBtnIsDisebled(true)
            const hash = 'fcdef8a4eed38ec88a23ed8facbd8dad7c774e3356dc223f5c0aa88ced9716e3'
            window.location.href = `https://shop.customessaymeister.com/offer/${hash}`
        } catch (error) {
            console.error(error)
        }
        finally {
            setBtnIsDisebled(false)
        }        
    }

    return (
        <>
            <Seo {...pageData} />
            <Layout>
                <div className="page-trial-intro">
                    <div className="wrapper">
                        <div className="page-trial-intro--content">
                            <Breadcrumbs steps={steps}/>
                            <h1 className="page-intro__title">Testdrive our service</h1>
                            <p className="page-trial-intro--subtitle">
                                We are very confident that you will like our service! 
                                Therefore, we give every new client a unique opportunity to place the first order absolutely free of charge to assess the quality of our service. 
                                Get started right away!
                            </p>
                            <button
                                className="button button-primary"
                                disabled={btnIsDisebled}
                                onClick={redirect}
                            >
                                Order free of charge
                            </button>
                        </div>                    
                    </div>
                </div>
                <div className="page-trial__content wrapper">
                    <h2 className="page-trial__content-title">
                        Try our service <span className="font-colored">for free</span>
                    </h2>
                    <div className="page-trial__content-cards">
                        {cards.map((item, index) => 
                            <div className="trial-card__wrapper">
                                <div
                                    key={index}
                                    className="trial-card"
                                >
                                    <div className="trial-card__icon">
                                        <img src={item.icon} alt={item.alt} />
                                    </div>
                                    <div className="trial-card__info">
                                        <div className="trial-card__title">
                                            <span>{ index + 1 }.</span>
                                            <span className="trial-card__title-name" dangerouslySetInnerHTML={item.title}></span>
                                        </div>
                                        <div>
                                            <p>{ item.description }</p>
                                        </div>
                                    </div>
                                </div>
                                <img className="trial-card__arrow" src={TrialArrow} alt="arrow" />
                            </div>
                        )}
                        
                    </div>
                    <div className="page-trial__content-button">
                        <button
                            className="button button-primary page-trial__content-btn"
                            disabled={btnIsDisebled}
                            onClick={redirect}
                        >
                            start now
                        </button>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default FreeTrialPage